import React, { FC, ReactNode } from "react";
import { Box, makeStyles, Grid, Hidden } from "@material-ui/core";
import { Theme } from "src/theme";
import Page from "src/components/Page";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    [theme.breakpoints.up("md")]: {
      padding: 40
    }
  },
  cardContainer: {
    borderRadius: 4,
    overflow: "hidden",
    maxWidth: theme.breakpoints.values.lg,
    backgroundColor: "white",
    boxShadow: "0 0 0 2px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)"
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  logoContainer: {
    backgroundColor: theme.palette.primary.dark,
    position: "relative",
    overflow: "hidden"
  },
  logo: {
    position: "absolute",
    bottom: -50,
    right: -50
  },
  gridItem: {
    height: 500,
    width: "100%"
  }
}));

const AuthLayout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Login">
      <Grid container spacing={0} className={classes.cardContainer}>
        <Grid item sm={12} md={6} style={{ width: "100%" }}>
          <Box className={clsx(classes.gridItem, classes.cardContent)}>{children}</Box>
        </Grid>
        <Hidden smDown>
          <Grid item md={6} className={clsx(classes.logoContainer, classes.gridItem)}>
            <img alt="Logo" className={classes.logo} src={"/static/ollie-logo-45-degrees.svg"} />
          </Grid>
        </Hidden>
      </Grid>
    </Page>
  );
};

export default AuthLayout;
